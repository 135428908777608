import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import "./Register.css";
import IMAGES from "../img/image";
import HowItWorks from "../components/Home/HowItWorks";
import Cta from "../components/Home/Cta";
import Faq from "../components/Home/Faq";

const Register = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState({});
  const [otp, setOtp] = useState(null);
  const [error, setError] = useState(false);

  // email otp
  const generateOTP = () => {
    const emailOtp = Math.floor(100000 + Math.random() * 900000);
    setOtp(emailOtp);
  };

  // uniqueId
  function generateUserID() {
    const prefix = "PR";
    const randomNumbers = Math.floor(Math.random() * 9000000) + 1000000; // Generate random 7-digit number
    const userID = `${prefix}${randomNumbers}`;
    setForm({ ...form, msId: userID });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Update the form state
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
    if (
      name === "profileCreatedFor" &&
      [
        "Son",
        "Daughter",
        "Brother",
        "Sister",
        "Myself",
        "Friend/Relative",
      ].includes(value)
    ) {
      const defaultGender =
        value === "Son" || value === "Brother"
          ? "Male"
          : value === "Daughter" || value === "Sister"
          ? "Female"
          : value === "Myself" || value === "Friend/Relative"
          ? "Select"
          : "";
      setForm((prevForm) => ({ ...prevForm, gender: defaultGender }));
    }
    if (name === "password" && form?.password?.length > 8) {
      setError(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (form?.password !== form?.confirmpassword) {
      return message.error("Password is not matching");
    }
    if (form?.password?.length < 8) {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 2000);
      return;
    }
    try {
      const res = await axios.post("/api/user/register", form);
      if (res.data.success) {
        message.success(res.data.message);
        navigate("/complete-profile");
        localStorage.setItem("email", form.email);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    generateOTP();
    generateUserID();
  }, []);

  return (
    <Layout>
      <div className="container-fluid hero-container">
        <div className="row">
          <div className="hero-section-img col-12 col-sm-12 col-md-6 col-lg-6 p-0 m-0">
            <img src={IMAGES.herobg} alt="" />
            <h4 className="hero-heading">
              #1 Halal Rishtey – India's First Anti-Dowry Muslim Matrimony
              Platform
            </h4>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 bg-white border">
            <form className="register-form" onSubmit={handleSubmit}>
              <h5>Create your profile now!</h5>
              <hr className="m-0 p-0 mb-4" />
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <div className="mb-3 form-fields">
                    <label className="form-label" htmlFor="">
                      Profile Creating For
                    </label>
                    <select
                      onChange={handleChange}
                      name="profileCreatedFor"
                      className="form-select"
                    >
                      <option value="Select">Select</option>
                      <option value="Myself">Myself</option>
                      <option value="Son">Son</option>
                      <option value="Daughter">Daughter</option>
                      <option value="Brother">Brother</option>
                      <option value="Sister">Sister</option>
                      <option value="Friend/Relative">Friend/Relative</option>
                    </select>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <div className="mb-3 form-fields">
                    <label htmlFor="" className="form-label">
                      {form?.profileCreatedFor === "Son" ||
                      form?.profileCreatedFor === "Brother"
                        ? "Groom"
                        : form?.profileCreatedFor === "Daughter" ||
                          form?.profileCreatedFor === "Sister"
                        ? "Bride"
                        : ""}
                      Name*
                    </label>
                    <input
                      onChange={handleChange}
                      placeholder="Enter full name"
                      type="text"
                      className="form-control"
                      required
                      name="username"
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <div className="mb-3 form-fields">
                    <label htmlFor="" className="form-label">
                      Email*
                    </label>
                    <input
                      onChange={handleChange}
                      placeholder="Enter your email"
                      type="email"
                      className="form-control"
                      required
                      name="email"
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <div className="mb-3 form-fields">
                    <label htmlFor="" className="form-label">
                      Gender
                    </label>
                    <select
                      onChange={handleChange}
                      value={form?.gender}
                      className="form-select"
                      name="gender"
                    >
                      <option value="Select">Select</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <div className="mb-3 form-fields">
                    <label htmlFor="" className="form-label">
                      Password*
                    </label>
                    <input
                      onChange={handleChange}
                      placeholder="Enter your password"
                      type="text"
                      className="form-control"
                      required
                      name="password"
                    />
                    {error && (
                      <span className="text-danger">
                        <small>Password must be 8 characters</small>
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <div className="mb-3 form-fields">
                    <label htmlFor="" className="form-label">
                      Confirm Password*
                    </label>
                    <input
                      onChange={handleChange}
                      placeholder="Confirm your password"
                      type="text"
                      className="form-control"
                      required
                      name="confirmpassword"
                    />
                  </div>
                </div>
              </div>
              <p className="m-0">
                <small>
                  By clicking on 'Register Free', you confirm that you accept
                  the{" "}
                  <Link style={{ color: "#066759" }} to="/terms-and-conditions">
                    Terms of Use
                  </Link>{" "}
                  and{" "}
                  <Link style={{ color: "#066759" }} to="/privacy-policy">
                    Privacy Policy
                  </Link>
                </small>
              </p>
              <p className="m-0 my-2 d-block d-lg-none">
                <small>
                  Already a Member? <Link to="/login">Login Here</Link>
                </small>
              </p>
              <button className="register-btn">Register Free</button>
            </form>
          </div>
        </div>
      </div>
      <HowItWorks />
      <Cta />
      <Faq />
    </Layout>
  );
};

export default Register;
