import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import "./Header.css";
import SideMenu from "./SideMenu";
import Backdrop from "./Backdrop";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import IMAGES from "../../img/image";
import { setUser } from "../../redux/features/userSlice";
import axios from "axios";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Tippy from "@tippyjs/react";
import LogoutTippy from "./LogoutTippy";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import HelpTippy from "./HelpTippy";

const Header = () => {
  const { user } = useSelector((state) => state.user);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [sideMenu, setSideMenu] = useState(false);
  const [profileMenu, setProfileMenu] = useState(false);
  const [allUser, setAllUser] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("token");
    message.success("Logout Successful");
    navigate("/login");
  };

  const whoLikeMe = allUser?.filter((item) =>
    user?.received?.map((item) => item.msId)?.includes(item.msId)
  );

  const getAllUser = async (gender, country) => {
    try {
      setLoading(true);
      const res = await axios.post(
        "/api/user/get-all-users",
        {
          country: country,
          gender: gender === "Male" ? "Female" : "Male",
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setAllUser(res.data.data);
      } else {
        message.error(res.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getUserData = async () => {
    axios
      .post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        dispatch(setUser(res.data.data));
        if (user) {
          getAllUser(res.data.data.gender, res.data.data.country);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div className="">
      <header className="header">
        <div className="header-main">
          <SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu} />
          <Backdrop sideMenu={sideMenu} setSideMenu={setSideMenu} />
          <div
            className="logo"
            onClick={() => (user ? navigate("/user-dashboard") : navigate("/"))}
          >
            <span className="text-white caveat fs-4">Halal Rishtey</span>
          </div>
          <div className="menus d-none d-md-none d-lg-block">
            <ul className="p-0">
              {user && (
                <>
                  <li>
                    <Link to="/user-dashboard">My Dashboard</Link>
                  </li>
                  <li>
                    <Link to="/ms-users">Matches</Link>
                  </li>
                  {/* <li>
                    <Link to="/search">Search</Link>
                  </li> */}
                  <li style={{ position: "relative" }}>
                    <Link to="/inbox-received">Inbox</Link>
                    {whoLikeMe?.length > 0 && (
                      <span className="counter">{whoLikeMe?.length}</span>
                    )}
                  </li>
                </>
              )}
              <div className="action-btn">
                {!user &&
                  (location.pathname === "/login" ? (
                    <div className="text-white">
                      <>Not a User?</>
                      <button
                        onClick={() => navigate("/register")}
                        className="login-btn ms-2"
                      >
                        Register
                      </button>
                    </div>
                  ) : (
                    <div className="text-white">
                      Already a User?
                      <button
                        onClick={() => navigate("/login")}
                        className="login-btn ms-2"
                      >
                        Login
                      </button>
                    </div>
                  ))}
              </div>
            </ul>
          </div>
          <div
            className="burger-icon d-block d-lg-none"
            onClick={() => setSideMenu(!sideMenu)}
          >
            <MenuOpenIcon className="icon" />
          </div>
          {user && (
            <div className="d-none d-md-none d-lg-block">
              <button
                onClick={() => navigate("/premium-plans")}
                className="upgrade-btn"
              >
                <WorkspacePremiumIcon className="me-1 icon" />
                Upgrade Now
              </button>
              <Tippy
                interactive
                theme="light"
                user={user && user}
                content={<HelpTippy />}
              >
                <span className="text-white mx-3 px-2 help-menu">
                  Help
                  <KeyboardArrowDownIcon />
                </span>
              </Tippy>
              <Tippy
                interactive
                theme="light"
                content={<LogoutTippy user={user && user} />}
              >
                <span
                  // onClick={handleLogout}
                  className="menu-img-container"
                >
                  <img
                    className="menu-img"
                    src={
                      user?.images?.length > 0
                        ? user?.images[0]
                        : user?.gender === "Male"
                        ? IMAGES.male
                        : IMAGES.female
                    }
                    alt=""
                  />
                  <KeyboardArrowDownIcon className="text-white" />
                </span>
              </Tippy>
            </div>
          )}
        </div>
        {user && (
          <div className="sub-header">
            {user &&
              (location.pathname === "/user-dashboard" ||
                location.pathname === "/profile" ||
                location.pathname === `/profile/${params.id}` ||
                location.pathname === "/my-photos" ||
                location.pathname === "/partner-preferences" ||
                location.pathname === "/settings" ||
                location.pathname === "/contact" ||
                location.pathname === "/about" ||
                location.pathname === "/terms-and-conditions" ||
                location.pathname === "/privacy-policy" ||
                location.pathname === "/refund-policy" ||
                location.pathname === "/verification" ||
                location.pathname === "/my-contact" ||
                location.pathname === "/premium-plans" ||
                location.pathname === "/edit-profile") && (
                <ul>
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/user-dashboard" && "active"
                      }`}
                      to="/user-dashboard"
                    >
                      Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/profile" && "active"
                      }`}
                      to="/profile"
                    >
                      Profile
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/my-photos" && "active"
                      }`}
                      to="/my-photos"
                    >
                      Photos
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/partner-preferences" && "active"
                      }`}
                      to="/partner-preferences"
                    >
                      PartnerPreferences
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      className={`${
                        location.pathname === "/settings" && "active"
                      }`}
                      to="/settings"
                    >
                      Settings
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${location.pathname === "/more" && "active"}`}
                      to="/more"
                    >
                      More
                    </Link>
                  </li> */}
                </ul>
              )}
            {user &&
              (location.pathname === "/today-matches" ||
                location.pathname === "/ms-users" ||
                location.pathname === "/near-me" ||
                location.pathname === "/recently-viewed" ||
                location.pathname === "/more-matches") && (
                <ul>
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/ms-users" && "active"
                      }`}
                      to="/ms-users"
                    >
                      All Users
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/today-matches" && "active"
                      }`}
                      to="/today-matches"
                    >
                      Today Matches
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/near-me" && "active"
                      }`}
                      to="/near-me"
                    >
                      Near Me
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/recently-viewed">RecentlyViewed</Link>
                  </li>
                  <li>
                    <Link to="/more-matches">MoreMatches</Link>
                  </li> */}
                </ul>
              )}
            {user &&
              (location.pathname === "/search" ||
                location.pathname === "/advance-search") && (
                <ul>
                  {/* <li>
                    <Link to="/search">Basic Search</Link>
                  </li> */}
                  {/* <li>
                    <Link to="/advance-search">Advance Search</Link>
                  </li> */}
                </ul>
              )}
            {user &&
              (location.pathname === "/inbox-received" ||
                location.pathname === "/inbox-accepted" ||
                location.pathname === "/inbox-sent" ||
                location.pathname === "/inbox-deleted") && (
                <ul>
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/inbox-received" && "active"
                      }`}
                      to="/inbox-received"
                    >
                      Received
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/inbox-accepted" && "active"
                      }`}
                      to="/inbox-accepted"
                    >
                      Accepted
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/inbox-sent" && "active"
                      }`}
                      to="/inbox-sent"
                    >
                      Sent
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/inbox-deleted" && "active"
                      }`}
                      to="/inbox-deleted"
                    >
                      Deleted
                    </Link>
                  </li>
                </ul>
              )}
            {/* {user &&
              (location.pathname === "/verify-email" ||
                location.pathname === "/my-contact") && (
                <ul>
                  <li>
                    <Link
                      className={`${
                        location.pathname === "/verify-email" && "active"
                      }`}
                      to="/verification"
                    >
                      Verification
                    </Link>
                  </li>
                </ul>
              )} */}
          </div>
        )}
      </header>
    </div>
  );
};

export default Header;
