import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import IMAGES from "../img/image";
import { message } from "antd";
import axios from "axios";
import "./Contact.css";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../redux/features/userSlice";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import TelegramIcon from "@mui/icons-material/Telegram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const Contact = () => {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    name: "",
    email: "",
    mobile: "",
    msg: "",
  });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post("/api/contact/add-contact-form", form);
      if (res.data.success) {
        message.success(res.data.message);
        setForm({ name: "", email: "", mobile: "", msg: "" });
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserData = async () => {
    axios
      .post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        dispatch(setUser(res.data.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getUserData();
  }, []);
  return (
    <Layout>
      <div className="contact-page-container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 contact-image">
            <img src={IMAGES.c3} alt="" />
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 contact-form">
            <h5>Feel free to message us!</h5>
            <div className="hr-line mb-4"></div>
            <form onSubmit={handleSubmit}>
              <div className="mb-2 form-fields">
                <label className="form-label" htmlFor="name">
                  Name
                </label>
                <input
                  onChange={handleChange}
                  name="name"
                  className="form-control"
                  type="text"
                  value={form.name}
                  required
                  placeholder="Enter your name"
                />
              </div>
              <div className="mb-2 form-fields">
                <label className="form-label" htmlFor="name">
                  Email
                </label>
                <input
                  onChange={handleChange}
                  name="email"
                  className="form-control"
                  type="email"
                  value={form.email}
                  required
                  placeholder="Enter your email"
                />
              </div>
              <div className="mb-2 form-fields">
                <label className="form-label" htmlFor="name">
                  Mobile
                </label>
                <input
                  onChange={handleChange}
                  name="mobile"
                  className="form-control"
                  type="text"
                  value={form.mobile}
                  required
                  placeholder="Enter your phone number"
                />
              </div>
              <div className="mb-2 form-fields">
                <label className="form-label" htmlFor="name">
                  Message
                </label>
                <textarea
                  onChange={handleChange}
                  className="form-control"
                  value={form.msg}
                  required
                  name="msg"
                  rows="7"
                  placeholder="How can we help you?"
                ></textarea>
              </div>
              <button type="submit" className="register-btn m-0">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="container-fluid contact-address-email">
        <a
          target="_blank"
          className="text-center c-address shadow m-3"
          href="https://maps.app.goo.gl/P4FjnjnGsx1DfRZc6"
          style={{ textDecoration: "none", color: "black" }}
        >
          <LocationOnIcon style={{ fontSize: "45px" }} />
          <p>
            <b>Hyderabad</b>
          </p>
        </a>
        <a
          target="_blank"
          className="text-center c-address shadow m-3"
          href="mailto:hello@mymuslimsaathi.com"
          style={{ textDecoration: "none", color: "black" }}
        >
          <EmailIcon style={{ fontSize: "45px" }} />
          <p>
            <b>support@halalrishtey.com</b>
          </p>
        </a>
        <a
          target="_blank"
          className="text-center c-address shadow m-3"
          href="https://wa.me/918125751585"
          style={{ textDecoration: "none", color: "black" }}
        >
          <WhatsAppIcon style={{ fontSize: "45px" }} />
          <p>
            <b>Whatsapp</b>
          </p>
        </a>
      </div>
    </Layout>
  );
};

export default Contact;
