import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallIcon from "@mui/icons-material/Call";
import { useDispatch } from "react-redux";
import axios from "axios";
import { message } from "antd";
import IMAGES from "../../../img/image";
import { setUser } from "../../../redux/features/userSlice";
import LikeThisProfile from "../LikeThisProfile";
import ImageModal from "../ImageModal";

const ProfileCard = ({ activeUser, data, imageLoading, setImageLoading }) => {
  const params = useParams();
  const navigate = useNavigate();
  const [userImage, setUserImage] = useState(0);
  const [imageModal, setImageModal] = useState(false);

  const handleImage = (direction) => {
    if (params.id) {
      if (direction === "left") {
        if (userImage === 0) {
          setUserImage(0);
        } else {
          setUserImage(userImage - 1);
        }
      } else if (direction === "right") {
        if (userImage === data?.images?.length - 1) {
          setUserImage(data?.images?.length - 1);
        } else {
          setUserImage(userImage + 1);
        }
      }
    } else {
      if (direction === "left") {
        if (userImage === 0) {
          setUserImage(0);
        } else {
          setUserImage(userImage - 1);
        }
      } else if (direction === "right") {
        if (userImage === activeUser?.images?.length - 1) {
          setUserImage(activeUser?.images?.length - 1);
        } else {
          setUserImage(userImage + 1);
        }
      }
    }
  };

  return (
    <div className="hero-profile-section">
      {!params.id && (
        <div className="hero-profile-photo">
          <img
            onClick={() => setImageModal(!imageModal)}
            src={
              data?.images && data?.images?.length === 0
                ? data?.gender === "Male"
                  ? IMAGES.male
                  : IMAGES.female
                : data?.images && data?.images[userImage]
            }
            alt="loading.."
          />
          {data?.images && data?.images.length > 1 && (
            <>
              <div className="arrow left-arrow">
                <ArrowCircleLeftIcon
                  onClick={() => handleImage("left")}
                  className="icon"
                />
              </div>
              <div className="arrow right-arrow">
                <ArrowCircleRightIcon
                  onClick={() => handleImage("right")}
                  className="icon"
                />
              </div>
            </>
          )}
          <div className="check-photos" style={{ backgroundColor: "#eee" }}>
            <Link style={{ color: "#000" }} to="/my-photos">
              Check My Photos
            </Link>
          </div>
        </div>
      )}

      {params.id && (
        <div className="hero-other-user-profile-photo">
          <div
            className={`hero-other-profile-image-container photo ${
              imageLoading && "active"
            }`}
          >
            {imageLoading ? (
              ""
            ) : data &&
              data?.photoPrivacy === "Only Visible to Premium Members" ? (
              <img
                src={data?.gender === "Male" ? IMAGES.male : IMAGES.female}
                alt="userImg"
              />
            ) : (
              <img
                onClick={() => setImageModal(!imageModal)}
                src={
                  data?.images && data?.images?.length > 0
                    ? data?.images[userImage]
                    : data?.gender === "Male"
                    ? IMAGES.male
                    : IMAGES.female
                }
                alt="loading.."
              />
            )}
            {data?.photoPrivacy !== "Only Visible to Premium Members" &&
              data?.images &&
              data?.images.length > 1 && (
                <>
                  <div className="arrow left-arrow">
                    <ArrowCircleLeftIcon
                      onClick={() => handleImage("left")}
                      className="icon"
                    />
                  </div>
                  <div className="arrow right-arrow">
                    <ArrowCircleRightIcon
                      onClick={() => handleImage("right")}
                      className="icon"
                    />
                  </div>
                </>
              )}
          </div>
          <LikeThisProfile data={data} activeUser={activeUser} />
        </div>
      )}
      {/* IMAGE MODAL  */}
      <ImageModal
        data={data}
        userImage={userImage}
        setUserImage={setUserImage}
        imageModal={imageModal}
        setImageModal={setImageModal}
      />

      <div className="profile-name w-100 my-3 text-center d-block d-flex gap-2 justify-content-start align-items-center d-lg-none">
        <h5 className="text-center m-0 p-0 ps-3">
          {data?.username} ({data?.msId})
        </h5>
        <button
          className="register-btn"
          onClick={() => navigate(`/profile-pdf/${data?.msId}`)}
        >
          Download Biodata
        </button>
      </div>

      {!params.id && (
        <div className="hero-profile-data">
          <div className="data-one">
            <div className="data">
              <div className="data-title">
                <small>Age</small>
              </div>
              <div className="data-answer">
                <small>: {data?.age || "Not Specified"}</small>
              </div>
            </div>
            <div className="data">
              <div className="data-title">
                <small>Marital Status</small>
              </div>
              <div className="data-answer">
                <small>: {data?.maritalStatus || "Not Specified"}</small>
              </div>
            </div>
            <div className="data">
              <div className="data-title">
                <small>Posted By</small>
              </div>
              <div className="data-answer">
                <small>
                  :{" "}
                  {data?.profileCreatedFor === "Myself"
                    ? "Myself"
                    : data?.profileCreatedFor === "Son"
                    ? "Parents"
                    : data?.profileCreatedFor === "Daughter"
                    ? "Parents"
                    : data?.profileCreatedFor === "Brother"
                    ? "Brother"
                    : data?.profileCreatedFor === "Sister"
                    ? "Sister"
                    : data?.profileCreatedFor === "Friend"
                    ? "Friends"
                    : "" || "Not Specified"}
                </small>
              </div>
            </div>
          </div>
          <div className="data-two">
            <div className="data">
              <div className="data-title">
                <small>Community</small>
              </div>
              <div className="data-answer">
                <small>: {data?.community || "Not Specified"}</small>
              </div>
            </div>
            <div className="data">
              <div className="data-title">
                <small>Location</small>
              </div>
              <div className="data-answer">
                <small>: {data?.city || "Not Specified"}</small>
              </div>
            </div>
            <div className="data">
              <div className="data-title">
                <small>Mother Tonngue</small>
              </div>
              <div className="data-answer">
                <small>: {data?.language || "Not Specified"}</small>
              </div>
            </div>
          </div>
          <div className="w-100">
            <hr className="d-none d-lg-block" />
          </div>
          <div className="more-data d-none d-md-none d-lg-block">
            <div className="manage-profile">
              <span>
                <b>Manage your profile</b>
              </span>
            </div>
            <div className="manage-profile-options">
              <ul className="options">
                <li>
                  <Link to="/edit-profile">
                    <small>- Edit Personal Profile</small>
                  </Link>
                </li>
                <li>
                  <Link to="/user-dashboard">
                    <small>- View Profile Stats</small>
                  </Link>
                </li>
                <li>
                  <Link to="/partner-preferences">
                    <small>- Edit Partner Profile</small>
                  </Link>
                </li>
                <li>
                  <Link to="/my-photos">
                    <small>- Add Photos</small>
                  </Link>
                </li>
                <li>
                  <Link to="/my-contact">
                    <small>- Edit Contact Details</small>
                  </Link>
                </li>
                <li>
                  <Link>
                    <small>- Hide / Delete Profile</small>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileCard;
