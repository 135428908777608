import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import "./AdminDashboard.css";
import axios from "axios";
import { message } from "antd";
import GroupIcon from "@mui/icons-material/Group";
import ManIcon from "@mui/icons-material/Man";
import WomanIcon from "@mui/icons-material/Woman";
import VerifiedIcon from "@mui/icons-material/Verified";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { useNavigate } from "react-router-dom";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [queryCount, setQueryCount] = useState(null);
  const [userCount, setUserCount] = useState(null);
  const [maleCount, setMaleCount] = useState(null);
  const [femaleCount, setFemaleCount] = useState(null);
  const [VerifiedUsersCount, setVerifiedUsersCount] = useState(null);
  const [NonVerifiedUsersCount, setNonVerifiedUsersCount] = useState(null);
  const [premiumUsersCount, setPremiumUsersCount] = useState(null);
  const [nonPremiumUsersCount, setNonPremiumUsersCount] = useState(null);
  const [error, setError] = useState(null);

  const getUserCount = async () => {
    try {
      const res = await axios.get("/api/admin/user-count", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setUserCount(res.data.data.totalUsers);
        setMaleCount(res.data.data.maleUsers);
        setFemaleCount(res.data.data.femaleUsers);
        setVerifiedUsersCount(res.data.data.VerifiedUsers);
        setNonVerifiedUsersCount(res.data.data.NonVerifiedUsers);
        setPremiumUsersCount(res.data.data.premiumUsers);
        setNonPremiumUsersCount(res.data.data.nonPremiumUsers);
        setQueryCount(res.data.data.queriesCount);
      } else {
        message.error("Error");
      }
    } catch (error) {
      console.error("Error fetching user count:", error);
      setError("Failed to fetch user count. Please try again later.");
    }
  };

  useEffect(() => {
    getUserCount();
  }, []);

  return (
    <AdminLayout>
      <div className="page-title">
        <h3 className="m-0">Dashboard</h3>
      </div>
      <hr />
      <div className="admin-dashboard-container">
        <div
          className="admin-dashboard-card"
          onClick={() => navigate("/admin-users")}
        >
          <div className="details">
            <h1>
              <b>{userCount || 0}</b>
            </h1>
            <span>All Users</span>
          </div>
          <div className="icon-container">
            <GroupIcon className="icon" />
          </div>
        </div>
        <div
          className="admin-dashboard-card"
          onClick={() => navigate("/admin-users/Male")}
        >
          <div className="details">
            <h1>
              <b>{maleCount || 0}</b>
            </h1>
            <span>Male Users</span>
          </div>
          <div className="icon-container">
            <ManIcon className="icon" />
          </div>
        </div>
        <div
          className="admin-dashboard-card"
          onClick={() => navigate("/admin-users/Female")}
        >
          <div className="details">
            <h1>
              <b>{femaleCount || 0}</b>
            </h1>
            <span>Female Users</span>
          </div>
          <div className="icon-container">
            <WomanIcon className="icon" />
          </div>
        </div>
        <div
          className="admin-dashboard-card"
          onClick={() => navigate("/admin-users/Verified")}
        >
          <div className="details">
            <h1>
              <b>{VerifiedUsersCount || 0}</b>
            </h1>
            <span>Verified Users</span>
          </div>
          <div className="icon-container">
            <VerifiedIcon className="icon" />
          </div>
        </div>
        <div
          className="admin-dashboard-card"
          onClick={() => navigate("/admin-users/Non Verified")}
        >
          <div className="details">
            <h1>
              <b>{NonVerifiedUsersCount || 0}</b>
            </h1>
            <span>Non Verified Users</span>
          </div>
          <div className="icon-container">
            <UnpublishedIcon className="icon" />
          </div>
        </div>
        <div
          className="admin-dashboard-card"
          onClick={() => navigate("/admin-users/Premium Users")}
        >
          <div className="details">
            <h1>
              <b>{premiumUsersCount || 0}</b>
            </h1>
            <span>Premium Users</span>
          </div>
          <div className="icon-container">
            <WorkspacePremiumIcon className="icon" />
          </div>
        </div>
        <div
          className="admin-dashboard-card"
          onClick={() => navigate("/admin-users/Non Premium Users")}
        >
          <div className="details">
            <h1>
              <b>{nonPremiumUsersCount || 0}</b>
            </h1>
            <span>Non Premium Users</span>
          </div>
          <div className="icon-container">
            <NoAccountsIcon className="icon" />
          </div>
        </div>{" "}
        <div className="admin-dashboard-card">
          <div className="details">
            <h1>
              <b>{queryCount || 0}</b>
            </h1>
            <span>Support</span>
          </div>
          <div className="icon-container">
            <SupportAgentIcon className="icon" />
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminDashboard;
