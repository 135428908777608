import { message } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const ImproveProfile = ({ user, loading }) => {
  const [percentage, setPercentage] = useState(null);
  const navigate = useNavigate();

  // Define fields to exclude from the completion calculation
  const excludedFields = [
    "_id ",
    "__v ",
    "msId ",
    "emailOtp ",
    "mobileVerified ",
    "idVerified ",
    "isActive ",
    "isVerified ",
    "idProof ",
    "contactData ",
    "likesCount ",
    "likesData ",
    "received ",
    "accepted ",
    "sent ",
    "deleted ",
    "lastLogin ",
    "createdAt ",
    "partnerAgeFrom ",
    "partnerAgeTo ",
    "partnerCity ",
    "partnerCountry ",
    "partnerLanguage ",
    "partnerMaslak ",
    "partnerMaritalStatus ",
    "partnerState ",
    "partnerReligion ",
    "partnerWorkingWith ",
    "partnerEmployedAs ",
    "partnerSalary ",
    "partnerDiet ",
    "partnerProfileCreatedFor ",
    "isDeleted ",
    "mobileOtp ",
    "partnerEducation ",
    "password ",
    "photoPrivacy ",
    "brothersMarried ",
    "sistersMarried ",
    "zakat ",
    "fasting ",
    "collegeName ",
    "premiumValidityMonths ",
    "premiumStart ",
    "premiumExpiry ",
    "premiumExpiredMail ",
    "contactsWarningEmailSent ",
    "premiumExpiryWarning ",
  ];

  const updateExcludedFields = () => {
    if (user?.qualification !== "Other") {
      excludedFields.push("collegeName");
    }
    if (user?.workingWith !== "Business/Self Employed") {
      excludedFields.push("business");
    }
    if (user?.workingWith === "Business/Self Employed") {
      excludedFields.push("employedAs");
    }
    if (user?.workingWith === "Not Working") {
      excludedFields.push("business", "employedAs", "salary");
    }
    if (user?.maritalStatus === "Single (Never Married)") {
      excludedFields.push("childrens", "childrensCount");
    }
    if (user?.otherCommunity !== "other") {
      excludedFields.push("otherCommunity");
    }
  };

  const calculateCompletionPercentage = () => {
    if (!user) return 0;
    // Call the function to update excludedFields
    updateExcludedFields();
    const filteredUser = { ...user };
    excludedFields.forEach((field) => {
      delete filteredUser[field.trim()];
    });
    // Count the number of filled fields
    const filledFields = Object.values(filteredUser).reduce(
      (count, value) =>
        count +
        (value !== null &&
        value !== undefined &&
        value !== "" &&
        (!Array.isArray(value) || (Array.isArray(value) && value.length > 0))
          ? 1
          : 0),
      0
    );
    // Calculate percentage
    return Math.round((filledFields / Object.keys(filteredUser).length) * 100);
  };

  useEffect(() => {
    const completionPercentage = calculateCompletionPercentage();
    setPercentage(completionPercentage);
    return () => {};
  }, [user]);

  return (
    <div className={`improve-profile-container loading ${loading && "active"}`}>
      <div className="profile-range-status">
        {percentage === 100 ? (
          <>
            <span>
              {percentage}% profile Completed
              <CheckCircleIcon className="text-success" />
            </span>
            <div className="range my-2">
              <div
                style={{ width: `${percentage}%` }}
                className="percentage"
              ></div>
            </div>
          </>
        ) : (
          <>
            <span>
              Profile Completed {percentage}%
              <PriorityHighIcon className="text-danger" />
            </span>
            <div className="range my-2">
              <div
                style={{ width: `${percentage}%` }}
                className="percentage"
              ></div>
            </div>
          </>
        )}
      </div>
      <div className="improve-profile">
        {user?.mobile === null ? (
          <>
            <h6 className="m-0">
              When you have not updated your number, no one will contact you!
            </h6>
            <span className="mb-3">
              <small>
                Note: Mobile increase the chances of getting in touch with your
                matches.
              </small>
            </span>
            <button
              className="register-btn mt-3"
              onClick={() => navigate("/verification")}
            >
              Let's Go
            </button>
          </>
        ) : percentage < 97 ? (
          <>
            <h6 className="m-0">
              Increase your chances of visibilty & more user matches
            </h6>
            <span className="mb-3">
              <small>
                Note: when your profile is incomplete your matches chances are
                very low.
              </small>
            </span>
            <button
              className="register-btn mt-3"
              onClick={() => navigate("/edit-profile")}
            >
              Let's Go
            </button>
          </>
        ) : user?.images?.length === 0 ? (
          <div className="profile-complete-success">
            <img src="" alt="" />
            <div>
              <h6>
                Your profile is the impression you present to those you match
                with.
              </h6>
              <span className="d-flex flex-column justify-content-center align-items-center">
                <small>
                  Add your photos to your profile for better visibility
                </small>
                <button
                  onClick={() => navigate("/my-photos")}
                  className="register-btn mt-3"
                >
                  Add Photos
                </button>
              </span>
            </div>
          </div>
        ) : user?.mobile === null || user?.mobile === "" ? (
          <div className="profile-complete-success">
            <img src="" alt="" />
            <div>
              <h6>Show others that you are a Verified Member!</h6>
              <span className="d-flex flex-column justify-content-center align-items-center">
                <small>Verify your mobile number now</small>
                <button
                  onClick={() => navigate("/verification")}
                  className="register-btn mt-3"
                >
                  Let's Go
                </button>
              </span>
            </div>
          </div>
        ) : (
          <div className="profile-complete-success">
            <img src="" alt="" />
            <div>
              <h6>
                Your profile is the impression you present to those you match
                with.
              </h6>
              <span className="d-flex flex-column justify-content-center align-items-center">
                <small>Go ahead and check out your matches</small>
                <button
                  onClick={() => navigate("/ms-users")}
                  className="register-btn mt-3"
                >
                  View Today Matches
                </button>
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImproveProfile;
