import React from "react";
import { Link } from "react-router-dom";
import "./AdminSidebar.css";
import HomeIcon from "@mui/icons-material/Home";
import GroupIcon from "@mui/icons-material/Group";
import PaymentIcon from "@mui/icons-material/Payment";
import PixIcon from "@mui/icons-material/Pix";
import EmailIcon from "@mui/icons-material/Email";
import DrawIcon from "@mui/icons-material/Draw";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import SendIcon from "@mui/icons-material/Send";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";

const AdminSidebar = () => {
  return (
    <div className="admin-sidebar-container">
      <span>
        <small>MAIN</small>
      </span>
      <ul>
        <li>
          <Link to="/admin-dashboard">
            <HomeIcon className="me-2" />
            Dashboard
          </Link>
        </li>
        {/* <li>
          <Link>
            <AccountCircleIcon className="me-2" />
            Profile
          </Link>
        </li> */}
      </ul>
      <span>
        <small>LISTS</small>
      </span>
      <ul>
        <li>
          <Link to="/admin-users">
            <GroupIcon className="me-2" />
            Users
          </Link>
        </li>
        <li>
          <Link to="/admin-payments">
            <PaymentIcon className="me-2" />
            Payments
          </Link>
        </li>
        {/* <li>
          <Link to="/admin-advertisement">
            <AdsClickIcon className="me-2" />
            Advertisement
          </Link>
        </li> */}
        <li>
          <Link to="/admin-plans">
            <PixIcon className="me-2" />
            Premium Plans
          </Link>
        </li>
        <li>
          <Link to="/admin-bulk-email">
            <EmailIcon className="me-2" />
            Bulk Email
          </Link>
        </li>
        <li>
          <Link to="/admin-blog">
            <DrawIcon className="me-2" />
            Add Blog
          </Link>
        </li>
        <li>
          <Link to="/admin-send-profile">
            <SendIcon className="me-2" />
            Send Profiles
          </Link>
        </li>
        <li>
          <Link to="/premium-expiry">
            <CardMembershipIcon className="me-2" />
            Premium Expiring
          </Link>
        </li>
        <li>
          <Link to="/admin-queries">
            <ContactSupportIcon className="me-2" />
            Queries
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default AdminSidebar;
