import React, { useEffect, useState } from "react";
import { BsFillShieldLockFill } from "react-icons/bs";
import OtpInput from "otp-input-react";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../../firebase/firebase.config";
import { message } from "antd";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../redux/features/userSlice";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./MobileVerify.css";

const MobileVerify = ({ formTab, setFormTab }) => {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();
  const [countryConfirm, setCountryConfirm] = useState(false);
  // otp
  const [otp, setOtp] = useState(null);
  const [ph, setPh] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    digitError: false,
    mobileExistError: false,
  });
  const [showOTP, setShowOTP] = useState(false);
  const [resendOtp, setResendOtp] = useState(0);
  const [email, setEmail] = useState(0);

  // ============================= FAST2SMS OTP =============================
  // send OTP
  const onSignUp = async (e) => {
    e.preventDefault();
    // Check if ph is not a number or is empty
    if (!ph || isNaN(ph)) {
      setError({ ...error, digitError: true });
      setTimeout(() => {
        setError({ ...error, digitError: false });
      }, 2000);
      return;
    }
    const formattedPh = ph.startsWith("91") ? ph.substring(2) : ph;
    // Check the resendOtp limit
    if (parseInt(resendOtp) < 5) {
      setLoading(true);
      const mobile = await axios.post("/api/user/check-mobile-number", {
        ph: ph,
      });
      if (mobile.data.success) {
        setLoading(false);
        setError({ ...error, mobileExistError: true });
        setTimeout(() => {
          setError({ ...error, mobileExistError: false });
        }, 3000);
        return;
      }
      try {
        const res = await axios.post("/api/user/sendSMS", {
          email: email,
          mobile: formattedPh,
        });
        if (res.data.success) {
          setLoading(false);
          message.success(res.data.message);
          setShowOTP(true);
          localStorage.setItem("resendOtp", parseInt(resendOtp) + 1);
          setResendOtp(localStorage.getItem("resendOtp"));
        } else {
          message.error(res.data.message);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    } else {
      setLoading(false);
      message.error("You have reached limits for OTP");
    }
  };
  // VERIFY OTP
  const onOTPVerify = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post("/api/user/verify-mobile", {
        email: email,
        otp: otp,
        mobile: ph,
        message: location.pathname === "/complete-profile" ? "Profile" : "",
      });
      if (res.data.success) {
        if (localStorage.getItem("email")) {
          setFormTab(1);
        } else {
          navigate("/user-dashboard");
          message.success("Mobile Verified Successful");
        }
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // Saving number of other countries
  const onSaving = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post("/api/user/save-number", { email, ph });
      if (res.data.success) {
        if (localStorage.getItem("email")) {
          setFormTab(1);
        } else {
          navigate("/user-dashboard");
          message.success("Mobile Saved Successful");
        }
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // ============================= FAST2SMS OTP =============================

  useEffect(() => {
    if (user) {
      setPh(user?.mobile);
    }
    if (localStorage.getItem("email")) {
      setEmail(localStorage.getItem("email"));
    } else {
      if (user) {
        setEmail(user?.email);
      }
    }
    if (localStorage.getItem("resendOtp")) {
      setResendOtp(localStorage.getItem("resendOtp"));
    } else {
      localStorage.setItem("resendOtp", parseInt(resendOtp));
    }
  }, []);

  return (
    <form
      className={`${
        location.pathname === "/complete-profile" && "mobile-verify-container"
      } form`}
    >
      <div id="recaptcha-container"></div>
      {user?.mobileVerified === "Yes" ? (
        <form className="complete-profile-form">
          <div className="text-center bg-white w-100">
            <VerifiedUserIcon className="text-center fs-1 text-success mb-2" />
            <br />
            <span>Your Mobile is Verified</span>
          </div>
        </form>
      ) : (
        <div className="row">
          {showOTP ? (
            <div className="col-12 text-center col-sm-12 col-md-12 col-lg-12">
              <div className="mb-3 form-fields">
                <label className="form-label" htmlFor="">
                  Enter Verification OTP sent on <br /> {ph}
                </label>
                <div className="d-flex justify-content-center">
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    autoFocus
                    OtpType="number"
                    disabled={false}
                    className="otp-container"
                    OTPLength={6}
                    maxTime={60}
                  ></OtpInput>
                </div>
              </div>
              <button onClick={onOTPVerify} className="continue-btn">
                {loading ? (
                  <>
                    <span
                      className="me-2 spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span>Verifying</span>
                  </>
                ) : (
                  <span>Verify OTP</span>
                )}
              </button>
              <h6 className="mt-3">
                Wrong Number?{" "}
                <span
                  style={{ cursor: "pointer" }}
                  className="text-primary"
                  onClick={() => setShowOTP(!showOTP)}
                >
                  Click Here
                </span>
              </h6>
            </div>
          ) : resendOtp >= 10 ? (
            <div className="text-center">
              <small>
                SORRY! <b>{user?.username}</b> You have reached OTP limit for
                Today. <Link to="/contact">Contact Us</Link>
              </small>
            </div>
          ) : (
            <div className="mobile-verification col-12 col-sm-12 col-md-6 col-lg-6">
              <div className="mb-3 form-fields">
                <label className="form-label" htmlFor="">
                  Enter Mobile Number*
                </label>
                {/* <input
                  type="text"
                  value={ph}
                  maxLength={10}
                  className="form-control"
                  placeholder="Enter 10 digits mobile number"
                  onChange={(e) => setPh(e.target.value)}
                /> */}
                <PhoneInput
                  country={"in"}
                  value={ph}
                  placeholder="Enter Phone Number"
                  onChange={(val) => {
                    setPh(val);
                  }}
                />
                {error.mobileExistError && (
                  <span className="text-danger m-0">
                    <small>Mobile number already registered with us</small>
                  </span>
                )}
                {error.digitError && (
                  <span className="text-danger m-0">
                    <small>Enter only 10 digits number</small>
                  </span>
                )}
              </div>
              {ph && ph.startsWith("91") ? (
                <button onClick={onSignUp} className="d-inline continue-btn">
                  {loading ? (
                    <>
                      <span
                        className="me-2 spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span>Sending..</span>
                    </>
                  ) : (
                    <span>Send code via SMS</span>
                  )}
                </button>
              ) : (
                <button onClick={onSaving} className="d-inline continue-btn">
                  {loading ? (
                    <>
                      <span
                        className="me-2 spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span>Saving..</span>
                    </>
                  ) : (
                    <span>Save My Number</span>
                  )}
                </button>
              )}
            </div>
          )}
        </div>
      )}
      {/* <p className="text-center mt-2">
      Didn't Recevied OTP?{" "}
      <span className="text-primary" onClick={handleResendOtp}>
        click here
      </span>
    </p> */}
    </form>
  );
};

export default MobileVerify;
