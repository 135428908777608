import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import InboxUserCard from "./components/Inbox/InboxUserCard";
import { message, Pagination } from "antd";
import { setUser } from "../redux/features/userSlice";
import axios from "axios";
import "./Inbox.css";

const Inbox = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [allUser, setAllUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [jumpToPage, setJumpToPage] = useState("");

  const getUserData = async () => {
    axios
      .post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        dispatch(setUser(res.data.data));
        getAllUser(res.data.data.gender, res.data.data.country);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllUser = async (gender, country) => {
    try {
      setLoading(true);
      const res = await axios.post(
        "/api/user/get-all-users",
        {
          country: country,
          gender: gender === "Male" ? "Female" : "Male",
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setAllUser(res.data.data);
      } else {
        message.error(res.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const whoLikeMe = allUser?.filter((item) =>
    user?.received?.map((item) => item.msId)?.includes(item.msId)
  );
  const usersPerPage = 5;
  const totalPages = Math.ceil(whoLikeMe?.length / usersPerPage);
  const startIndex = (currentPage - 1) * usersPerPage;
  const endIndex = Math.min(startIndex + usersPerPage, whoLikeMe?.length);
  const displayedUsers = whoLikeMe?.slice(startIndex, endIndex);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleJumpToPage = () => {
    const pageNumber = parseInt(jumpToPage, 10);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <Layout>
      <div className="inbox-container">
        <div className="inbox-all-users">
          {loading ? (
            <div className="loading-container">
              <div class="loader">
                <span class="loader-text">loading</span>
                <span class="load"></span>
              </div>
            </div>
          ) : (
            user &&
            displayedUsers?.map((item) => {
              const receivedUser = user?.received.find(
                (receivedItem) => receivedItem.msId === item.msId
              );
              const isAdmin = receivedUser?.admin === true; // Check if admin is true
              console.log(isAdmin);
              return (
                <InboxUserCard
                  admin={isAdmin}
                  user={user}
                  item={item}
                  key={item.id}
                />
              );
            })
          )}
          {whoLikeMe?.length === 0 && (
            <div className="loading-container">No Records Found</div>
          )}
        </div>
        <div className="text-center mt-3">
          <Pagination
            current={currentPage}
            total={whoLikeMe?.length}
            pageSize={usersPerPage}
            onChange={handlePageChange}
            showSizeChanger={false}
            showQuickJumper
            showTotal={(total) => `Total ${total} users`}
          />
        </div>
      </div>
    </Layout>
  );
};

export default Inbox;
