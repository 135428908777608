import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import IMAGES from "../img/image";
import axios from "axios";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import "./Blogs.css";

const Blogs = () => {
  const navigate = useNavigate();
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    // autoplay: true,
    // speed: 2000,
    // autoplaySpeed: 2000,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 786,
        settings: {
          dots: false,
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: false,
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [blogs, setBlogs] = useState(null);

  const getAllBlogs = async () => {
    try {
      const res = await axios.get("/api/blog/get-all-blog");
      if (res.data.success) {
        setBlogs(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllBlogs();
  }, []);

  return (
    <>
      <div className="profile-slider-container">
        <div className="my-5 ">
          <h1
            className="text-center fs-1 fw-bold poppins"
            style={{ color: "#26bd68" }}
          >
            Our Blogs
          </h1>
          <div
            className="text-center mt-3"
            style={{
              width: "100px",
              height: "3px",
              backgroundColor: "#26bd68",
              margin: "auto",
            }}
          ></div>
        </div>
        <div className="blogs">
          <Slider {...settings}>
            {blogs &&
              blogs?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="blog-container"
                    onClick={() => navigate(`/blog/${item.title}`)}
                  >
                    <div className="blog">
                      <div className="blog-img">
                        <img src={`blogImages/${item.image}`} alt="" />
                      </div>
                      <div className="blog-content">
                        <h6>{item.title}</h6>
                        {/* <span>
                          <small>{item.description}</small>
                        </span> */}
                      </div>
                    </div>
                  </div>
                );
              })}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default Blogs;
