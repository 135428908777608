import React from "react";
import "./Features.css";
import IMAGES from "../../img/image";

const Features = () => {
  return (
    <div className="features-container">
      <div className="my-5 ">
        <h1
          className="text-center fs-1 fw-bold poppins"
          style={{ color: "#26bd68" }}
        >
          Halal Rishtey Features
        </h1>
        <div
          className="text-center mt-3"
          style={{
            width: "100px",
            height: "3px",
            backgroundColor: "#26bd68",
            margin: "auto",
          }}
        ></div>
      </div>
      <p>
        Get ready to experience a world of convenience with our feature-packed
        platform that covers all your Nikah needs.
        {/* Halal Rishtey is a Anti-dowry
        platform. */}
      </p>
      <div className="features-section">
        <div className="features">
          <img src={IMAGES.shield} alt="" />
          <h5>Verified Profiles</h5>
          <p>
            <small>
              Ensure authenticity and build trust by implementing a profile
              verification system, allowing users to showcase their verified
              status for added credibility.
            </small>
          </p>
          <div className="f-line"></div>
        </div>
        <div className="features">
          <img src={IMAGES.like} alt="" />
          <h5>Send Likes</h5>
          <p>
            <small>
              Effortlessly express interest! Send likes to admired profiles,
              simplifying connections for an enjoyable and streamlined
              experience in building relationships.
            </small>
          </p>
          <div className="f-line"></div>
        </div>
        <div className="features">
          <img src={IMAGES.privacy2} alt="" />
          <h5>Photo Privacy</h5>
          <p>
            <small>
              Safeguard your memories with our photo privacy settings. Control
              who sees your moments, ensuring your cherished photos remain
              private and secure.
            </small>
          </p>
          <div className="f-line"></div>
        </div>
        <div className="features">
          <img src={IMAGES.algo} alt="" />
          <h5>Matching Algorithm</h5>
          <p>
            <small>
              Employ an advanced matching algorithm that considers user
              preferences, interests, and compatibility factors to suggest
              highly relevant connections.
            </small>
          </p>
          <div className="f-line"></div>
        </div>
      </div>
    </div>
  );
};

export default Features;
