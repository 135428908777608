import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import axios from "axios";
import { useSelector } from "react-redux";
import "./Dashboard.css";
import { Link, useNavigate } from "react-router-dom";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import IMAGES from "../img/image";
import ImproveProfile from "./components/Dashboard/ImproveProfile";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import WarningIcon from "@mui/icons-material/Warning";
import { message } from "antd";
import LockIcon from "@mui/icons-material/Lock";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const Dashboard = () => {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [activeUser, setActiveUser] = useState(null);
  const [userImage, setUserImage] = useState(0);
  const [imageLoader, setImageLoader] = useState(false);
  const [offer, setOffer] = useState(null);
  const [allUser, setAllUser] = useState(null);
  const [loading, setLoading] = useState(false);

  const getAllUser = async (gender, country) => {
    try {
      setLoading(true);
      const res = await axios.post(
        "/api/user/get-all-users",
        {
          country: country,
          gender: gender === "Male" ? "Female" : "Male",
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setAllUser(res.data.data);
      } else {
        message.error(res.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const whoLikeMe = allUser?.filter((item) =>
    user?.received?.map((item) => item.msId)?.includes(item.msId)
  );
  const whoAcceptedMe = allUser?.filter((item) =>
    user?.accepted?.map((item) => item.msId)?.includes(item.msId)
  );
  const sentRequest = allUser?.filter((item) =>
    user?.likesData?.map((item) => item.msId)?.includes(item.msId)
  );

  const handleImage = (direction) => {
    if (direction === "left") {
      if (userImage === 0) {
        setUserImage(0);
      } else {
        setUserImage(userImage - 1);
      }
    } else if (direction === "right") {
      if (userImage === activeUser?.images?.length - 1) {
        setUserImage(activeUser?.images?.length - 1);
      } else {
        setUserImage(userImage + 1);
      }
    }
  };

  // get offer
  // const getAds = async () => {
  //   try {
  //     const res = await axios.get("/api/admin/get-ads", {
  //       headers: {
  //         Authorization: "Bearer " + localStorage.getItem("token"),
  //       },
  //     });
  //     if (res.data.success) {
  //       setOffer(res.data.data);
  //     } else {
  //       message.error(res.data.message);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getUserData = async () => {
    setImageLoader(true);
    axios
      .post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          setActiveUser(res.data.data);
          getAllUser(res.data.data.gender, res.data.data.country);
          setImageLoader(false);
        } else {
          setImageLoader(false);
          localStorage.removeItem("token");
        }
      })
      .catch((error) => {
        setImageLoader(false);
        console.log(error);
      });
  };

  useEffect(() => {
    getUserData();
    // getAds();
  }, []);

  const checkTokenExpiration = () => {
    const token = localStorage.getItem("token");
    console.log("running");
    if (token) {
      try {
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        const currentTimestamp = Math.floor(Date.now() / 1000);
        if (decodedToken.exp && decodedToken.exp < currentTimestamp) {
          console.log("Token has expired");
          localStorage.removeItem("token");
          navigate("/login");
        }
      } catch (error) {
        console.error("Error decoding or checking JWT token:", error);
      }
    } else {
      console.log("No token found in local storage");
    }
  };

  useEffect(() => {
    checkTokenExpiration();
  }, []);

  return (
    <Layout>
      <div className="dashboard-container container">
        {/* {user?.isVerified === "No" && (
          <div className="under-verification text-center">
            <LockIcon className="fs-1 mb-3" />
            <h2>
              <b>Welcome! {user?.username}</b>
            </h2>
            <p>
              Your profile is under verification. For safety & security of our
              community we manually verify profiles. Please come back after
              sometime.
            </p>
            <button
              onClick={() => {
                localStorage.removeItem("token");
                navigate("/");
              }}
              className="register-btn mt-3"
            >
              Logout
            </button>
          </div>
        )} */}
        <div className="dashboard">
          <div className={`photo-container loading ${loading && "active"}`}>
            <div className={`photo `}>
              {loading ? (
                ""
              ) : (
                <img
                  src={
                    activeUser?.images?.length > 0
                      ? activeUser?.images[userImage]
                      : activeUser?.gender === "Female"
                      ? IMAGES.female
                      : IMAGES.male
                  }
                  alt=""
                />
              )}
              {activeUser?.images.length > 1 && (
                <>
                  <div className="arrow left-arrow">
                    <ArrowCircleLeftIcon
                      onClick={() => handleImage("left")}
                      className="icon"
                    />
                  </div>
                  <div className="arrow right-arrow">
                    <ArrowCircleRightIcon
                      onClick={() => handleImage("right")}
                      className="icon"
                    />
                  </div>
                </>
              )}
              {/* <CameraAltIcon className="icon" /> */}
            </div>
            <div
              className="edit-profile"
              onClick={() => navigate("/edit-profile")}
            >
              <div>
                <h5 className="m-0 p-0 fs-6">{activeUser?.username}</h5>
                <span className="text-muted m-0 p-0">
                  <small>{user?.msId}</small>
                </span>
              </div>
              <Link to="/edit-profile">
                <small>Edit Profile</small>
              </Link>
            </div>
            {!loading && <hr className="m-0 p-0" />}
            <div className="upgrade" onClick={() => navigate("/premium-plans")}>
              <div>
                <span className="text-muted m-0 p-0">
                  <small>Account Type</small>
                </span>
                <h5 className="m-0 p-0 fs-6">
                  {user?.contacts > 0 ? "Premium " : "Free Membership"}
                </h5>
              </div>
              <Link to="/premium-plans">
                {user?.contacts > 0 ? (
                  <WorkspacePremiumIcon
                    className="icon"
                    style={{ color: "#066759" }}
                  />
                ) : (
                  <small>Upgrade</small>
                )}
              </Link>
            </div>
            {!loading && <hr className="m-0 p-0" />}
            {user?.isActive === "Yes" ? (
              user?.mobileVerified === "Yes" ? (
                user?.idVerified === "approved" ? (
                  <div
                    className="mobile-verified"
                    onClick={() => navigate("/verification")}
                  >
                    <div>
                      <span className="text-muted m-0 p-0">
                        <small>100%</small>
                      </span>
                      <h5 className="m-0 p-0 fs-6">Profile Verified</h5>
                    </div>
                    <div className="verified-icon">
                      <CheckCircleOutlineIcon className="icon text-success" />
                    </div>
                  </div>
                ) : (
                  <div
                    className="mobile-verified"
                    onClick={() => navigate("/verification")}
                  >
                    <div>
                      <span className="text-muted m-0 p-0">
                        <small>ID is not verified</small>
                      </span>
                      <h5 className="m-0 p-0 fs-6">Verify Now</h5>
                    </div>
                    <div className="verified-icon">
                      <WarningIcon className="icon text-warning" />
                    </div>
                  </div>
                )
              ) : (
                <div
                  className="mobile-verified"
                  onClick={() => navigate("/verification")}
                >
                  <div>
                    <span className="text-muted m-0 p-0">
                      <small>Mobile number is not verified</small>
                    </span>
                    <h5 className="m-0 p-0 fs-6">Verify Now</h5>
                  </div>
                  <div className="verified-icon">
                    <WarningIcon className="icon text-warning" />
                  </div>
                </div>
              )
            ) : (
              <div
                className="mobile-verified"
                onClick={() => navigate("/verification")}
              >
                <div>
                  <span className="text-muted m-0 p-0">
                    <small>Account is inactive</small>
                  </span>
                  <h5 className="m-0 p-0 fs-6">Verify Your email</h5>
                </div>
                <div className="verified-icon">
                  <WarningIcon className="icon text-warning" />
                </div>
              </div>
            )}
          </div>
          <div className={`activity-container`}>
            <h6
              className={`text-muted loading ${
                loading && "active w-lg-25 w-50"
              }`}
            >
              Your Activity Summary
            </h6>
            <div className="summaries-container">
              <div
                className={`summary-card loading ${loading && "active"}`}
                onClick={() => navigate("/inbox-received")}
              >
                <div className="summary-counter">
                  <span>{whoLikeMe?.length || 0}</span>
                </div>
                <div className="summary-title">
                  <span>Pending Invitation</span>
                </div>
              </div>
              <div
                className={`summary-card loading ${loading && "active"}`}
                onClick={() => navigate("/inbox-accepted")}
              >
                <div className="summary-counter">
                  <span>{whoAcceptedMe?.length || 0}</span>
                </div>
                <div className="summary-title">
                  <span> Accepted Invitation</span>
                </div>
              </div>
              <div
                className={`summary-card loading ${loading && "active"}`}
                onClick={() => navigate("/inbox-sent")}
              >
                <div className="summary-counter">
                  <span>{sentRequest?.length || 0}</span>
                </div>
                <div className="summary-title">
                  <span> Invitation Sent</span>
                </div>
              </div>
              <div className={`summary-card loading ${loading && "active"}`}>
                <div className="summary-title">
                  <span>
                    <i>Only Premium Members can avail these benefits {"->"}</i>
                  </span>
                </div>
              </div>
              <div className={`summary-card loading ${loading && "active"}`}>
                <div className="summary-counter">
                  <span>{user?.contactData?.length}</span>
                </div>
                <div className="summary-title">
                  <span>Contact Viewed</span>
                </div>
              </div>
              <div className={`summary-card loading ${loading && "active"}`}>
                <div className="summary-counter">
                  <span>{user?.contacts}</span>
                </div>
                <div className="summary-title">
                  <span>Contacts Available</span>
                </div>
              </div>
            </div>
            <h6
              className={`text-muted mt-3 loading ${
                loading && "active w-lg-25 w-50"
              }`}
            >
              Improve Your Profile
            </h6>
            <ImproveProfile loading={loading} user={user && user} />
          </div>
          <div className={`promotion-container loading ${loading && "active"}`}>
            {loading ? (
              ""
            ) : (
              <img
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/premium-plans")}
                src={IMAGES.offer}
                alt=""
              />
            )}
          </div>
          <div className="terms text-center d-block m-auto d-md-none d-lg-none">
            <span>
              <small>
                <Link to="/terms-and-conditions">Terms & Conditions</Link>|
                <Link to="/privacy-policy">Privacy Policy </Link>|
                <Link to="/refund-policy">Refund Policy</Link>
              </small>
            </span>
            <span>
              <small>© Copyright 2023-2024 HalalRishtey.com</small>
            </span>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
