import React from "react";
import Layout from "../components/Layout/Layout";
import "./Terms.css";

const Terms = () => {
  return (
    <Layout>
      <div className="terms-container">
        <h4>
          <b>Terms and Conditions</b>
        </h4>
        <br />
        <h5>Welcome to halalrishtey.com</h5>
        <p>
          This document is an electronic record in terms of the Information
          Technology Act, 2000 and rules there under pertaining to electronic
          records as applicable and amended. This electronic record is generated
          by a computer system and does not require any physical or digital
          signatures. This document is published in accordance with the
          provisions of Rule 3 (1) of the Information Technology (Intermediaries
          Guidelines) Rules, 2011 that require publishing the rules and
          regulations, privacy policy and the terms and conditions for access or
          usage of www.halalrishtey.com found at the uniform resource locator
          (URL) id. Please read the terms of use for this website carefully. In
          accordance with this terms and conditions, the users of
          halalrishtey.com are provided with limited license to use the service
          and content of this website. By signing up the service, you give your
          acceptance to be bound by the Terms and conditions herein referred as
          the "Agreement". To become a member of this website and to be able to
          communicate with fellow members, you must register as a member and
          follow the instructions given during the Registration process. This
          Agreement outlines the terms that you are subjected to as a member of
          this website. The Agreement may be revised from time to time at the
          sole discretion of halalrishtey.com and will be intimated to you.
          Pursuant to such changes, if you continue to use the site then it will
          be constituted as your acceptance for the changes.The halalrishtey.com
          site is only to facilitate personal advertisement for lawful marriage
          alliance between persons who are legally competent to enter into
          matrimony under the laws to which they are subject. In these Terms,
          references to "halalrishtey.com" member shall mean the end user
          accessing the Website/halalrishtey.com services, its contents or using
          the halalrishtey.com Services offered. halalrishtey.com member also
          includes persons such as father, mother, brother, sister, relative or
          a friend ("Registrant") of the prospective bride/ bridegroom. The
          terms "You" and "User" shall be interchangeably used for
          "halalrishtey.com member". "halalrishtey.com Services" means a
          collective reference to the service provided NIKA ONLINE PRIVATE
          LIMITED, CIN:U93000KL2018PTC051629 ,a company under the provisions of
          companies act, 2013 and having its registered office 19/349-A-15,City
          Complex, New Bus Stand, Badakara Kozhikode KL 673101 IN
        </p>
        <h5>Scope</h5>
        <p>
          You understand and acknowledge that halalrishtey.com acts as an
          "Intermediary" as defined under clause (1) sub-clause (w) of Section 2
          of the Information Technology Act, 2000.my halalrishtey.com is a brand
          owned by NIKA ONLINE PRIVATE LIMITED which owns, retains and has
          complete rights in halalrishtey.com and the halalrishtey.com Website
          /App/my halalrishtey.com Service.my halalrishtey.com is personalized
          match making services of NIKA ONLINE PRIVATE LIMITED , which is valid
          for a period of the applicable package chosen by you (and visible on
          this website) from the date of taking the halalrishtey.com package.
          halalrishtey.mycom is an Endeavour to help you find your better half
          (prospect) through the process of searching, short listing and
          contacting prospects, during the Tenure.my halalrishtey.com acts as a
          platform to enable any user to themselves register on it (by filling
          the mandatory fields and optional fields, if any) to voluntarily
          search for profile(s) from the database of halalrishtey.com`s .COM`s
          already registered users, for seeking prospective lawful matrimonial
          alliances for themselves.my halalrishtey.com retail store may also be
          able to assist you to create your profile; however, you must have a
          valid/operational mobile phone number and an email id. The profiles in
          the database of halalrishtey.com are classified broadly on the basis
          of language and region for the ease and convenience of its member /
          customer.my halalrishtey.com Members are provided with free/paid
          access for searching profiles from the database of halalrishtey.com as
          per the partner preference set by you (on the Website/Applications
          -(App) and you can shortlist the profiles in which you are interested
          .
        </p>
        <h5>Eligibility</h5>
        <ul>
          <li>
            a) halalrishtey.com Membership and rights of admission is reserved
            solely for Indian Nationals & Citizens. Persons of Indian Origin
            (PIO) Non Resident Indians (NRI) Persons of Indian Descent or Indian
            Heritage Persons who intend to marry persons of Indian Origin
          </li>
          <li>
            b) Further in capacity as halalrishtey.com member you confirm that
            you are : 18 years or above (if you are a woman) or 21 years or
            above (if you are a man); If you have applied for Divorce, you may
            register on our website/App by stating "Awaiting Divorce". If you
            are a resident of any other Country, you are legally competent to
            marry as per the local rules applicable to your country and you
            shall comply with the Indian laws for marrying a person of Indian
            Origin
          </li>
          <li>
            c) In case you are a Registrant of the prospective bride/ bridegroom
            and has created profile in halalrishtey.com Website/App on behalf of
            them or is accessing the halalrishtey.com Website/App on behalf of
            them implies that you have taken their consent for their profile
            creation in halalrishtey.com and for accessing the halalrishtey.com
            Website/App.
          </li>
        </ul>
        <h5>Registration</h5>

        <ul>
          <li>
            a) Registration presumes that the users have read, understood and
            accepted the terms and conditions..{" "}
          </li>
          <li>
            b) We expect that you would complete the online registration process
            with loyal and honesty in furnishing true, accurate, current,
            complete information and with providing recent photos of you which
            will help you to meet your expectation , You need to read the
            relevant column before entering in the details or selecting the
            option available or uploading the photo. In order to serve you
            better if halalrishtey.com requires additional details you agree to
            provide it. Further you may fill in your email id and phone number
            in any of our registration campaigns appearing in various websites
            as part of our advertisements, basis which you may receive call from
            our Customer Service Centre and assist you in getting your profile
            registered on our website/App
          </li>
          <li>
            c) halalrishtey.com also reserves the right to block the
            registration of your profile on Website/App, if any, in the case of
            your contact details/links being mentioned in restricted fields or
            if there are errors in any data entered by the halalrishtey.com
            members in their profile
          </li>
          <li>
            d) You acknowledge and confirm that your registration with
            halalrishtey.com and the usage of halalrishtey.com services is with
            the intention of marriage and not otherwise, halalrishtey.com
            Membership is restricted strictly to the registered halalrishtey.com
            individual member only. Organisations, companies, businesses and/or
            individuals carrying on similar or competitive business cannot
            become Members of halalrishtey.com and nor use the halalrishtey.com
            Service or halalrishtey.com members data for any commercial purpose,
            and halalrishtey.com reserves its right to initiate appropriate
            legal action for breach of these obligation. Registration of
            duplicate profiles of the same person is not allowed in
            halalrishtey.com Website/App. halalrishtey.com shall have full right
            to suspend or terminate (without any notice) such duplicate profile
          </li>
        </ul>
        <h5>Account Security</h5>
        <p>
          halalrishtey.com as a Company does not ask for Password and you are
          cautioned not to share your password to any persons. You are
          responsible for safeguarding the confidentiality of your
          halalrishtey.com login credentials such as your user id, password,
          OTP, etc. You agree to accept responsibility for all activities that
          occur over your account This site does not encourage and/or promote
          illegitimate sexual relations or affairs outside marriage. If any
          member is found to be using this site as a means of promoting or
          indulging or engaging in any illegitimate sexual relations or affairs
          outside marriage or if halalrishtey.com becomes aware of such
          activities then the membership of the particular user will be
          terminated without any refund. halalrishtey.com is not bound by any
          liability towards such individual. The binding and final termination
          will be the sole discretion of halalrishtey.com
        </p>
        <h5>Role and Responsibility of halalrishtey.com</h5>
        <ul>
          <li>
            a) halalrishtey.com is act as a mediator or provides an interface to
            its registered members to search their prospect themselves without
            any assistance
          </li>
          <li>
            b) halalrishtey.com cannot guarantee or assume responsibility for
            any specific results from the use of the data available from the
            halalrishtey.com service or from other matrimonial websites owned by
            Nike online
          </li>
          <li>
            c) The profile search conducted by any halalrishtey.com member and
            the matches shown thereof are automatically generated by
            halalrishtey.com, and based on the partner preference set by you. In
            the event of halalrishtey.com member changing their partner
            preference on the Website/App, then the automated system generated
            prospect results of the Website/App may also undergo corresponding
            change.
          </li>
          <li>
            d) We cannot guarantee the complete protection of user data while it
            is in transit, or prevent any tampering of the data by a third party
            with malicious intent before the data reaches the halalrishtey.com
            servers.
          </li>
          <li>
            e) halalrishtey.com does not prohibit any halalrishtey.com member
            from sending interest to your profile or communicating to you based
            on their partner preference. But you have the freedom to deny their
            interest or proceed further if you are interested.
          </li>
        </ul>
        <h5>Role and Responsibility of halalrishtey.com Member</h5>
        <ul>
          <li>
            a) To get better responds, halalrishtey.com Members are expected to
            provide latest photograph which should not be more than 3 (three)
            months old. Providing old photographs/ photographs of others,
            inaccurate / false information shall be treated as violation of
            terms and conditions and halalrishtey.com shall retain their right
            under clause 2 (b) of this terms and conditions. Uploaded photos
            should be in good clarity. We approve photos without Sunglass and
            Caps only. Also you must avoid sideview clicks.
          </li>
          <li>
            b) Members are advised to refrain from Entering into any financial
            transactions with prospects. halalrishtey.com Members shall not seek
            financial help or provide financial help from / to the other
            halalrishtey.com Members. Using abusive language when they
            communicate with the other person considered as the violation of
            terms and condition.
          </li>
          <li>
            c) The halalrishtey.com members agrees that for getting effective
            search results of prospects on Website/App you will regularly log in
            to the profile maintained in the Website/App and express interest
            which is an automated messages to prospects as a free
            halalrishtey.com member, and in the event you have availed paid
            halalrishtey.com package, you can send personalised messages to
            prospects to show your expression of interest/replies.
            halalrishtey.com member also may review the expression of interest
            sent to you / read the messages sent to you by prospects and may
            respond suitably.
          </li>
          <li>
            d) If the Customer fails to update in halalrishtey.com website/App,
            any change in the particulars furnished in their profile/ then the
            membership may be suspended
          </li>
          <li>
            e) In case of finalisation of his/her marriage, delete their profile
            by themselves or inform to the customer care for removal/deletion of
            their profile
          </li>
          <li>
            f) You will not post or transmit any content that is abusive,
            obscene, sexually oriented or against national interest. Nika Online
            reserves the right to suspend your profile if any prohibitive or
            objectionable content is found and may further initiate appropriate
            legal proceedings against you.
          </li>
          <li>
            g) All the users are required to submit their profile with the
            necessary information required for establishing a matrimonial
            profile. Nika Online will not be responsible for any loss or damage
            caused for Concealing facts relevant to marriage
          </li>
          <li>
            h) Nika Online does not guarantee the legality and validity of the
            content provided by the members. This may include information
            related age, Occupation, religion, caste or creed or any other
            personal information.
          </li>
          <li>
            i) Use of Bots, EXE`s, CGI or any other programs/scripts to view
            content on or communicate/contact/respond/interact with
            halalrishtey.com and/or its Members is restricted
          </li>
        </ul>
        <h5>Privacy of Membership</h5>
        <p>
          Please read and follow our Privacy Policy which also governs your
          visit to halalrishtey.com and other websites owned by Nika Online Pvt
          Ltd, the personal information / data provided to us by you during the
          course of usage of halalrishtey.com will be treated as confidential
          and in accordance with the Privacy policy and applicable laws and
          regulations. To protect your privacy and understand our practices as
          amended from time to time, If you object to your information being
          transferred or used, please do not use the website.
        </p>

        <h5>Exchange of Information for CHAT & other Services</h5>
        <p>
          All information, data, text, video, messages or other materials,
          whether publicly or privately transmitted / posted, is the sole
          responsibility of the person from where such content originated (the
          Originator).Sending lewd, obscene or offensive content using
          halalrishtey.com chat is strictly prohibited using the services of
          halalrishtey.com Chat in a manner which is meant to be offensive to
          others shall render the user liable for prosecution under the existing
          laws as applicable. Nika Online assumes no responsibility for the
          nature of the content being exchanged using the halalrishtey.com Chat.
          The details of the SMS sent or received on the Mobile phone shall not
          be made available to the subscriber. The subscriber agrees that
          Company will not entertain any correspondence/calls in this regard.
        </p>
        <h5>Confidentiality</h5>
        <p>
          You agree that halalrishtey.com has all the right to process your data
          through the automated process to serve you with suitable profiles
          based on your partner preference from halalrishtey.com websites and
          may also send you communication in this regard. halalrishtey.com will
          maintain the confidentiality of all personal information other than
          that meant for sharing with the prospect provided by halalrishtey.com
          members and shall take all possible and /or steps for maintaining the
          confidentiality. However, halalrishtey.com member may disclose such
          information if required by law without prior intimation to you.
        </p>

        <h5>Age Criteria:</h5>
        <p>
          To register as a member of halalrishtey.com or use this Site, you must
          be of legal marriageable age as per the laws of India (currently, 18
          years and above for females and 21 and above for males.) The website
          is only to facilitate lawful marriages between persons who are legally
          competent to enter into matrimony under the laws to which they are
          subject.
        </p>

        <h5>Refund and Assignment</h5>
        <p>
          halalrishtey.com member shall not assign or transfer your
          halalrishtey.com membership to any other person or entity, and such an
          act shall be treated as violation of terms and conditions and
          halalrishtey.com shall retain their right under clause 2 (b) of this
          terms and conditions. The payments made by any halalrishtey.com
          members by way of membership/renewal fee/auto renewal are treated as
          non-refundable
        </p>

        <h5>Termination</h5>
        <p>
          If halalrishtey.com receives any complaint against you and
          consequently we have suspended/deleted your profile in good credit,
          then we shall not be responsible to you in any manner whatsoever,
          including for any loss, costs, expenses, or consequence, if any. Upon
          termination/suspension of membership, your right to use or access the
          website/service will cease immediately. All provisions of these terms
          and conditions that by their nature should survive termination shall
          endure termination, including, without limitation, disclaimers, and
          limitations of liability. Termination of your access to and use of the
          Services shall not relieve you of any obligations arising or accruing
          prior to such termination.
        </p>
      </div>
    </Layout>
  );
};

export default Terms;
