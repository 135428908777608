import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import { useSelector } from "react-redux";
import axios from "axios";
import "./PremiumPlans.css";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import IMAGES from "../img/image";
import VerifiedIcon from "@mui/icons-material/Verified";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import CheckIcon from "@mui/icons-material/Check";

const PremiumPlans = () => {
  const HowProfileWillLook = () => {
    return (
      <div className="how-profile-will-look-container">
        <h5 className="text-center">
          You will stand differently from other profiles!
        </h5>
        <div className="green-line mb-4"></div>
        <div className="new-matches-users-container premium-profile-container">
          {[1, 2, 3].map((item, index) => {
            return (
              <div className="profiles new-match-users">
                <div className="new-match-user-image">
                  {user?.photoPrivacy === "Visible to all Members" ? (
                    <img
                      src={
                        user?.images?.length > 0
                          ? user?.images[0]
                          : user?.gender === "Male"
                          ? IMAGES.male
                          : IMAGES.female
                      }
                      alt=""
                    />
                  ) : user?.photoPrivacy ===
                      "Only Visible to Premium Members" &&
                    user?.contacts > 0 ? (
                    <img
                      src={
                        user?.images.length > 0
                          ? user?.images[0]
                          : user?.gender === "Male"
                          ? IMAGES.male
                          : IMAGES.female
                      }
                      alt=""
                    />
                  ) : (
                    <img
                      src={
                        user?.gender === "Male" ? IMAGES.male : IMAGES.female
                      }
                      alt=""
                    />
                  )}
                </div>
                <div
                  className={`new-match-user-details ${
                    index === 1 && "premium"
                  }`}
                >
                  <div className="d-flex justify-content-end align-items-center">
                    {index === 1 && (
                      <span className="id-verified">
                        <VerifiedIcon className="icon" />
                        ID Verified
                      </span>
                    )}
                    {index === 1 && (
                      <span className="id-verified">
                        <WorkspacePremiumIcon className="icon" />
                        Premium
                      </span>
                    )}
                  </div>
                  <h6 className="m-0">
                    {index === 1 ? user?.username : "Other Profile"}
                  </h6>
                  <span className="text-muted">
                    <small>
                      <span>{user?.msId}</span>
                      {" | "}
                      <span>Last seen few hours ago</span>
                    </small>
                  </span>
                  <span className="user-details">
                    {user?.age} {user?.age && "Yrs"} {user?.height && " - "}
                    {user?.height?.split("-")[0]} {user?.maritalStatus && " - "}{" "}
                    {user?.maritalStatus}
                    {user?.state && " - "}
                    {user?.state} {user?.city && " - "} {user?.city}{" "}
                    {user?.qualification && " - "}
                    {user?.qualification} {user?.maslak && " - "} {user?.maslak}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const [allPlan, setAllPlan] = useState(null);
  const [loading, setLoading] = useState(null);
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [transactionId, setTransactionId] = useState(null);

  const getUserData = async () => {
    axios
      .post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  const generateTransactionId = async () => {
    const timestamp = Date.now();
    const random = Math.floor(Math.random() * 10000);
    const transactionId = `${timestamp}${random}`;
    setTransactionId(transactionId);
    return transactionId; // Return the generated transactionId
  };

  // get all plans
  const getAllPlan = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/api/admin/get-plans", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setAllPlan(res.data.data);
        setLoading(false);
      } else {
        message.error(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserData();
    generateTransactionId();
    getAllPlan();
  }, []);

  //! PAYMENT
  const paymentHandler = async (planName, price, contacts, validity) => {
    // e.preventDefault();
    //! mobile empty ka check kro bhai
    const orderData = {
      amount: parseInt(price),
      name: user?.username,
      email: user?.email,
      product: planName,
      mobile: user?.mobile,
      address: user?.state,
      callback_url: "/api/payment/payment-callback",
      cancel: "/api/payment/payment-cancel",
    };
    const response = await fetch("/api/payment/order", {
      method: "POST",
      body: JSON.stringify(orderData),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const order = await response.json();
    var options = {
      key: "rzp_live_VH5rPIzUEmop1D",
      amount: parseInt(order.amount),
      currency: order.currency,
      name: "Halal Rishtey",
      description: "Halal Rishtey Plan Purchase",
      image: "",
      order_id: order.id,
      handler: async function (response) {
        const body = {
          ...response,
        };
        const validateRes = await fetch("api/payment/validate", {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
          },
        });
        const jsonRes = await validateRes.json();
        if (jsonRes.success) {
          try {
            const res = await axios.post(
              "/api/payment/payment-success",
              {
                amount: price,
                name: user?.username,
                email: user?.email,
                product: planName,
                mobile: user?.mobile,
                address: user?.state,
                contacts: contacts,
                validity: validity,
                orderId: transactionId,
              },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              }
            );
            if (res.data.success) {
              navigate("/user-dashboard");
            } else {
              message.error(res.data.message);
            }
          } catch (error) {
            console.log(error);
          }
        }
      },
      prefill: {
        name: user?.username,
        email: user?.email,
        contact: user?.mobile,
      },
      notes: {
        address: "Halal Rishtey",
      },
      theme: {
        color: "#26bd68",
      },
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      alert("Error in Payment Gateway");
    });
    rzp1.open();
  };

  return (
    <Layout>
      <div className="container parent-plan-container p-5">
        <h5 className="text-center" style={{ lineHeight: "30px" }}>
          <b>Upgrade Now & Get upto 50% discount!</b>
        </h5>
        {loading ? (
          <div className="loading-container">
            <div class="loader">
              <span class="loader-text">loading</span>
              <span class="load"></span>
            </div>
          </div>
        ) : (
          <div className="plans-container">
            {allPlan?.map((plan, index) => {
              return (
                <div className={`plans-card ${index === 1 && "active"}`}>
                  <span className="name">{plan?.name}</span>
                  <p className="m-0">Access</p>
                  <h4 className="fw-bold m-0">{plan?.contacts} Contacts</h4>
                  <hr />
                  <p className="m-0 fw-bold">Services</p>
                  {plan?.features?.map((item, index) => {
                    return (
                      <>
                        <span>
                          <CheckIcon className="fs-6 me-1" />
                          <small>{item?.features}</small>
                        </span>
                        <br />
                      </>
                    );
                  })}
                  <br />
                  <h5 className="">
                    <span className="me-2">
                      <small>Price</small>
                    </span>
                    <small className="me-2 text-danger text-decoration-line-through">
                      Rs. {plan?.originalPrice}
                    </small>
                  </h5>
                  <h4 className="m-0">
                    <b> ₹ {plan?.price}</b>
                    <small>
                      <small> / {plan.validity} Months</small>
                    </small>
                  </h4>
                  <br />
                  <button
                    onClick={(e) =>
                      paymentHandler(
                        plan?.name,
                        plan?.price,
                        plan?.contacts,
                        plan?.validity
                      )
                    }
                    className="plan-btn register-btn"
                  >
                    Get Started
                  </button>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <HowProfileWillLook />
    </Layout>
  );
};

export default PremiumPlans;
