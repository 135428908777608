import React, { useEffect, useState } from "react";
import LockIcon from "@mui/icons-material/Lock";
import Slider from "react-slick";
import axios from "axios";
import { message } from "antd";
import IMAGES from "../../img/image";
import { useNavigate } from "react-router-dom";
import "./ProfileSlider.css";

const ProfileSlider = () => {
  const navigate = useNavigate();
  const [allUser, setAllUser] = useState(null);
  const [loading, setLoading] = useState(false);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 2,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 786,
        settings: {
          dots: false,
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: false,
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getAllUser = async (gender) => {
    try {
      setLoading(true);
      const res = await axios.post("/api/user/home-page-users");
      if (res.data.success) {
        setAllUser(res.data.data);
      } else {
        message.error(res.data.message);
      }
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllUser();
  }, []);

  return (
    <>
      {allUser?.length > 0 && (
        <div className="profile-slider-container">
          <div className="my-5 ">
            <h1
              className="text-center fs-1 fw-bold poppins"
              style={{ color: "#26bd68" }}
            >
              Users Registered With Us
            </h1>
            <div
              className="text-center mt-3"
              style={{
                width: "100px",
                height: "3px",
                backgroundColor: "#26bd68",
                margin: "auto",
              }}
            ></div>
          </div>
          <div className="profile">
            <Slider {...settings}>
              {allUser &&
                allUser
                  .map((user, index) => {
                    return (
                      <div
                        onClick={() => navigate("/login")}
                        key={index}
                        className="user-card"
                      >
                        <div className={`user loading ${loading && "active"}`}>
                          <div className="user-card-img">
                            <img
                              src={
                                user.gender === "Male"
                                  ? IMAGES.male
                                  : IMAGES.female
                              }
                              alt=""
                            />
                            <div className="icon">
                              <LockIcon />
                              <br />
                              <span>
                                <small>Login to View</small>
                              </span>
                            </div>
                          </div>
                          <span className="user-details user-content">
                            <h6 className="m-0">
                              <b>{user?.username}</b>
                            </h6>
                            <span className="text-muted">
                              <small>
                                <span>{user?.msId}</span>
                                <span>
                                  <small>
                                    {user?.lastLogin !== null && " | Active "}
                                    {user.lastLogin === null
                                      ? ""
                                      : new Date(
                                          user?.lastLogin
                                        ).toDateString() ===
                                        new Date().toDateString()
                                      ? `${new Date(
                                          user?.lastLogin
                                        ).toLocaleTimeString([], {
                                          hour: "2-digit",
                                          minute: "2-digit",
                                          hour12: true,
                                        })}`
                                      : new Date(
                                          user?.lastLogin
                                        ).toDateString()}
                                  </small>
                                </span>
                              </small>
                            </span>
                            <p className="m-0 mt-2">
                              {user?.age} {user?.age && "Yrs"}{" "}
                              {user?.height && " - "}
                              {user?.height?.split("-")[0]}{" "}
                              {user?.maritalStatus && " - "}{" "}
                              {user?.maritalStatus}
                            </p>
                            <p className="m-0">
                              {user?.state} {user?.city && " - "} {user?.city}{" "}
                            </p>
                            <p className="m-0">{user?.qualification}</p>
                          </span>
                        </div>
                      </div>
                    );
                  })
                  .slice(0, 20)}
            </Slider>
          </div>
        </div>
      )}
    </>
  );
};

export default ProfileSlider;
