import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "./Testimonials.css";
import IMAGES from "../../img/image";

const Testimonials = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    // autoplay: true,
    // speed: 2000,
    // autoplaySpeed: 2000,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 786,
        settings: {
          dots: false,
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: false,
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const testimonials = [
    {
      name: "Khusum Ahmed",
      review:
        "The most trusted matrimonial site providing 24/7 service. Great initiative✨ Appreciable ( Masha Allah )May Allah reward your team with incredible success.",
      img: `${IMAGES.female}`,
    },
    {
      name: "Mohd Zuber",
      review:
        "Great initiative. Most trusted matrimonial site with 24/7service.",
      img: `${IMAGES.male}`,
    },
    {
      name: "Mohammed Shakeel Salman",
      review:
        "This group is absolutely genuine and the teàm was awesome and working 24×7  for us, and i got my soulmate through halal rishtey. Thank you so much team halal rishtey you pepole are very polite and awesome great work thank you so much, again and again.",
      img: `${IMAGES.male}`,
    },
    {
      name: "Nousheen Sultana",
      review:
        "Good initiative by halal matrimony  to make nikah asaan may ALLAH give them massive success  (ameen)  this matrimony  is very helpful  to directly  connect  with people  of our choice Without  any mediators .mashaallah  l liked there work.",
      img: `${IMAGES.female}`,
    },
    {
      name: "Hannan Shareef",
      review:
        "Best Muslim matrimony service.. nice management, good staff, best service I had ever seen..",
      img: `${IMAGES.male}`,
    },
    {
      name: "Mir Sarwer Ali",
      review:
        "Assalamualaikum, if I say the truth in today's times, my relationship would have been plundered by agents like Mushaita, or you would have achieved great success by helping the public at such a low price...may Allah bless you for your future, progress, or people. Instructed to avoid dowry",
      img: `${IMAGES.male}`,
    },
  ];

  return (
    <>
      <div className="profile-slider-container">
        <div className="my-5 ">
          <h1
            className="text-center fs-1 fw-bold poppins"
            style={{ color: "#26bd68" }}
          >
            People Said About Us
          </h1>
          <div
            className="text-center mt-3"
            style={{
              width: "100px",
              height: "3px",
              backgroundColor: "#26bd68",
              margin: "auto",
            }}
          ></div>
        </div>
        <div className="profile">
          <Slider {...settings}>
            {testimonials &&
              testimonials?.map((item, index) => {
                return (
                  <div key={index} className="testimonial-container">
                    <div className="testimonial">
                      <div className="t-name-img">
                        <img src={item.img} alt="" />
                        <h6>{item.name}</h6>
                      </div>
                      <span>
                        <small>{item.review}</small>
                      </span>
                    </div>
                  </div>
                );
              })}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default Testimonials;
