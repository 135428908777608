import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { message } from "antd";
import { useReactToPrint } from "react-to-print";
import AboutMyself from "../user/components/MyProfile.js/AboutMyself";
import IMAGES from "../img/image";
import html2pdf from "html2pdf.js";
import "./Pdf.css";

const ProfilePdfGenerator = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { user } = useSelector((state) => state.user);
  const [profile, setProfile] = useState(0);

  const componentPdf = useRef();

  const getProfileData = async () => {
    try {
      const res = await axios.post("/api/user/get-profile-data", {
        msId: params.id,
      });
      if (res.data.success) {
        setProfile(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProfileData();
  }, []);

  const data = params.id ? profile : user;
  console.log(data);

  const handleDownloadPDF = () => {
    const pdf = document.getElementById("pdf-container");
    var opt = {
      margin: 0,
      filename: data?.username + data?.age + data?.qualification + data?.city,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };
    html2pdf().from(pdf).set(opt).save();
  };

  return (
    <div>
      <div className="name-container mb-3">
        <div className="name">
          {data?.username} ({data?.msId})
          <button onClick={handleDownloadPDF}>Download PDF</button>
        </div>
      </div>
      <div
        id="pdf-container"
        className="px-5 py-5 pdf-container"
        ref={componentPdf}
      >
        <div className="watermark">Halalrishtey.com</div>
        <img
          width="30%"
          style={{ display: "block", margin: "20px auto" }}
          src={IMAGES.pdf}
          alt=""
        />
        <hr />
        <div className="user-field mb-4">
          <div className="about-myself-heading">
            <h4 style={{ color: "#066759", marginBottom: "-10px" }}>
              <b>{data?.username}</b>
            </h4>
          </div>
        </div>
        <hr />
        <AboutMyself data={data} />
        <Link style={{ color: "#fff" }} to="https://halalrishtey.com">
          <div
            className="pdf-footer text-center"
            style={{
              backgroundColor: "#26bd68",
              color: "#fff",
              padding: "10px",
            }}
          >
            Create your Profile on www.halalrishtey.com for Free |{" "}
            <Link style={{ color: "#fff" }} to="https://halalrishtey.com">
              Click here & Get Started Now
            </Link>
          </div>
        </Link>
      </div>

      {/* Hidden anchor element for direct download */}
      <a
        id="download-pdf-link"
        style={{ display: "none" }}
        download={`Halalrishtey_Profile_${data?.msId}.pdf`}
      ></a>
    </div>
  );
};

export default ProfilePdfGenerator;
